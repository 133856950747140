// eslint-disable-next-line import/no-cycle
import { APIService } from './ApiService';

export const getCalendlyInvitees = ({ page, limit, search, filter, filterCancelled, startDate, endDate }) =>
  APIService.get(
    `crm/calendly/invitees?page=${page}&limit=${limit}&search=${search}&filter=${filter}&filterCancelled=${filterCancelled}${
      startDate ? `&startDate=${startDate}` : ''
    }${endDate ? `&endDate=${endDate}` : ''}`,
  );

export const getNoPackagesCustomers = ({ page, limit, search, filter }) =>
  APIService.get(`crm/no-packages?page=${page}&limit=${limit}&search=${search}&filter=${filter}`);

export const getCustomer = email => APIService.get(`crm/calendly/customer/${email}`);

export const getActiveStudentActivities = email => APIService.get(`crm/customer/activity/${email}`);

export const getActiveUserOrders = userId => APIService.get(`orders/users/${userId}`);

export const getActiveUserShopOrders = email => APIService.get(`shop/orders/email/${email}`);

export const getActiveStudentTickets = userID => APIService.get(`tickets/user/${userID}`);

export const getModerationList = () => APIService.get('users/moderation');

export const getTeachersList = () => APIService.get('users/teachers');

export const addTask = task => APIService.post('crm/tasks', task);

export const editTask = task => APIService.put('crm/tasks', task);

export const getTasks = ({ page, limit, search, filter }) =>
  APIService.get(`crm/tasks?page=${page}&limit=${limit}&search=${search}&filter=${filter}`);

export const getUserTasks = ({ page, limit, search, filter }) =>
  APIService.get(`crm/tasks/assigned?page=${page}&limit=${limit}&search=${search}&filter=${filter}`);

export const addConversation = conversation => APIService.post('crm/conversations', conversation);

export const editConversation = ({ conversation }) => APIService.put(`crm/conversations`, conversation);

export const addCustomerDetails = customer => APIService.post('crm/customer/details', customer);

export const getCustomerDetails = email => APIService.get(`crm/customer/details/${email}`);

export const getActiveStudentTasks = (email, filter) => APIService.get(`crm/tasks/${email}?filter=${filter}`);

export const getActiveStudentConversations = email => APIService.get(`crm/conversations/${email}`);

export const getActiveStudentCalendlyInvitees = email => APIService.get(`crm/calendly/invitees/${email}`);

export const generateRecommendationCode = userId =>
  APIService.post('coupons/recommendation-codes/generate', { userId });

export const getActiveStudentTokens = userId => APIService.get(`users/tokens/${userId}`);

export const blockUser = userId => APIService.post(`users/${userId}/block`);

export const unblockUser = userId => APIService.post(`users/${userId}/unblock`);

export const getActiveStudentActivityGrid = userId => APIService.get(`materials/users/${userId}/activity`);

export const getCalendlyStats = ({ startDate, endDate }) =>
  APIService.get(
    `crm/calendly/stats?${startDate ? `startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}`,
  );

export const getStudentFlags = userId => APIService.get(`users/${userId}/flags/all`);

export const getStudentActiveFlags = userId => APIService.get(`users/${userId}/flags`);

export const addFlag = ({ userId, flag }) => APIService.post(`users/${userId}/flags`, { ...flag });

export const removeFlag = ({ userId, flagId }) => APIService.delete(`users/${userId}/flags/${flagId}`);

export const activateFlag = ({ userId, flagId }) => APIService.post(`users/${userId}/flags/${flagId}/activate`);

export const getStudentCart = userId => APIService.get(`products/carts/${userId}`);

export const addProductToStudentCart = ({ userId, productId }) =>
  APIService.put(`products/carts/${userId}`, { productId });

export const removeProductFromStudentCart = ({ userId, productId }) =>
  APIService.delete(`products/carts/${userId}`, { data: { productId } });

export const addCouponToStudentCart = ({ userId, coupon }) =>
  APIService.post(`products/carts/${userId}/coupons`, { couponText: coupon });

export const addRecommendationCodeToStudentCart = ({ userId, recommendationCode }) =>
  APIService.post(`products/carts/${userId}/recommendation-code`, { recommendationCode });

export const getProductsInCourse = courseId => APIService.get(`products/courses/${courseId}`);
