import Styled from 'styled-components';
import { PageHeader } from 'antd';

// SHARED STYLES (BETWEEN QUESTION DATABASE RELATED PAGES)
const PageHeaderStyle = Styled(PageHeader)`
  margin: 0px;
  .ant-page-header-heading-title {
    text-transform: none !important;
  font-size: 16px !important;
  font-weight: bold !important;
  @media only screen and (max-width: 468px){
 font-size: 12px !important;
            }
          }
  .page-header-actions button.ant-btn-white svg {
    width: 12px;
    height: 12px;
    ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 2px;
    color:  ${({ theme }) => theme.primaryButtonsColor};
  }
  i +span, svg +span, img +span {
      ${({ theme }) => (!theme.rtl ? 'margin-left' : 'margin-right')}: 6px;
  }
`;

const PageHeaderStyleV2 = Styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  font-weight: bold;

@media only screen and (max-width: 455px) {
  font-size: 12px;
}
`;

const MaterialsPageWrapper = Styled.div`
  padding: 30px 20px !important;
  padding-bottom: 10px !important;

@media only screen and (max-width: 699px){
  padding: 15px 20px !important;
  padding-bottom: 30px !important;
}
.modules-header{
    height: 30px;
    padding: 5px 0px !important;
    @media only screen and (max-width: 1199px){
      padding: 5px 0px !important;
  }
  @media only screen and (max-width: 767px){
    display: flex;
    height: 30px;
      justify-content: flex-start !important;
      align-items: center;
  }
    @media only screen and (max-width: 468px){
    display: flex;
    height: 30px;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0px !important;
  }
  }
.data-container {
display: flex;
flex-wrap: wrap;
gap: 15px;
margin-top: 25px;

@media only screen and (max-width: 699px){
  margin-top: 15px;
}

@media only screen and (max-width: 1024px) {
  display: grid;
grid-template-columns: 1fr 1fr 1fr;
}

@media only screen and (max-width: 768px) {
grid-template-columns: 1fr 1fr;
gap: 10px;
}

}

`;

const MaterialTile = Styled.div`
display: flex;
position: relative;
flex-direction: column;
width: 100%;
max-width: 335px; 
border-radius: 15px;
max-height: 400px;
background-color: #FFFFFF;


.blocked-tile {
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
border-radius: 12px;
background-color: rgba(6, 26, 44, 0.3);
color: #343434;
max-height: 400px;
display: flex;
padding: 10px;
gap: 5px;
justify-content: flex-start;
align-items: flex-start;
flex-direction: column;
@media only screen and (max-width: 455px) {
padding: 15px 10px;
}
}

.module-blocked-reason-text {
background-color: #FFFFFF; 
border-radius: 16px;
font-size: 11px;
font-weight: 500;
padding: 10px;
line-height: 14px;
@media only screen and (max-width: 455px) {
font-size: 9px;
padding: 5px 13px;
line-height: 11px;
}
@media only screen and (max-width: 375px) {
  padding: 5px 10px;
}
@media only screen and (max-width: 325px) {
  padding: 5px 2px;
}
}

.module-blocked-text {
background-color: #FFFFFF; 
border-radius: 15px;
font-size: 13px;
font-weight: bold;
padding: 10px;
display: flex;
gap: 5px;
align-items: center;
line-height: 14px;
@media only screen and (max-width: 455px) {
font-size: 11px;
max-height: 25px;
padding: 5px 13px;
svg {
  max-height: 10px !important;
  max-width: 10px !important;
  font-size: 10px !important;
}
}
@media only screen and (max-width: 375px) {
  padding: 5px 10px;
}
@media only screen and (max-width: 325px) {
  padding: 5px 2px;
}
}

.unblocked-tile{
  display: none !important;
}

.ant-progress-text{
  display: none;
}

.open-material-button{
  background-color:  ${({ theme }) => theme.primaryButtonsColor};
  border-color: ${({ theme }) => theme.primaryButtonsColor};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30px;
  border-radius: 10px;
  font-size: 13px;
  color: #FFFFFF;
  font-weight: bold;
  @media only screen and (max-width: 455px){
    font-size: 10px;
            }
            &:hover {
            background-color: ${({ theme }) => theme.hoverButtonsColor} !important;
            border: none !important;
            color: #FFFFFF !important;
        }
        &:focus{
          background-color:  ${({ theme }) => theme.primaryButtonsColor} !important;
          border: none !important;
          &:hover {
            background-color: ${({ theme }) => theme.hoverButtonsColor} !important;
          }
        }
}
.open-material-button:disabled{ 
  width: 100%;
  height: 30px;
  border-radius: 10px;
  font-size: 13px;
  color: #FFFFFF;
  font-weight: bold;
  background-color: ${({ theme }) => theme.disabledOpenMaterialsColor};
  border: none;
}

.material-img{
  border-radius: 15px 15px 0px 0px;
  position: absolute;  
top: 0;
left: 0;
width: 100%;
height: auto;
max-height: 130px;
object-fit: cover;
}
.material-img-div{
display: flex;
width: 100%;
height: 0; 
padding-top: 35%; 
position: relative; 
overflow: hidden;
min-height: 130px;
  @media only screen and (max-width: 455px){
    max-height: 24%;
    min-height: 74px;
}
@media only screen and (max-width: 375px){
    max-height: 20%;
    min-height: 64px;
}
}
`;

const MaterialProgressAndDescriptionWrapper = Styled.div`
display: flex; 
flex-direction: column; 
align-items: center;
padding: 26px 45px;
padding-top: 23px;
gap: 13px;
@media only screen and (max-width: 455px){
  padding: 16px 15px; 
  gap: 7px;           
}

.progress-bar-percent-and-button-wrapper{
display: flex;
width: 100%;
flex-direction: column;
gap: 29px;
justify-content: space-between;   
@media only screen and (min-width: 1400px){
  margin-top: 0px;
}
@media only screen and (max-width: 455px){
  gap: 13px;}       
 }

 .material-title{
  color: ${({ theme }) => theme.hoverButtonsColor};
  font-size: 13px;
  font-weight: bold;
  min-height: 40px;
  display: flex;
  line-height: 17px;
    text-align: center;
    align-items: center;
  @media only screen and (max-width: 455px){
    font-size: 10px;
    line-height: 13px;
            }
            @media only screen and (max-width: 390px){
              min-height: 39px;
              line-height: 13px;
            }
            @media only screen and (max-width: 325px){
              min-height: 49px;
            }
 }
 .material-description {
  color: ${({ theme }) => theme.hoverButtonsColor};
  font-size: 11px;
  font-weight: 400;
  min-height: 58px;
  max-height: 58px;
  overflow: hidden; 
  text-align: center;
  display: -webkit-box;
-webkit-line-clamp: 4; 
-webkit-box-orient: vertical;
text-overflow: ellipsis;
line-height: 14px;
  @media only screen and (max-width: 455px){
    font-size: 9px;
    min-height: 73px;
  max-height: 73px;
  -webkit-line-clamp: 6; 
  line-height: 12px;
            }
 }
 .percent-text{
  color: #292B3E;
  font-size: 11px;
  font-weight: bold;
  @media only screen and (max-width: 455px){
    font-size: 8px;
            }
 }
`;
const ProgressBarAndPercentWrapper = Styled.div`
 display: flex;
 width: 100%;
 flex-direction: column;
 align-items: flex-end;
 gap: 5px;
`;
export {
  PageHeaderStyle,
  MaterialsPageWrapper,
  MaterialTile,
  MaterialProgressAndDescriptionWrapper,
  ProgressBarAndPercentWrapper,
  PageHeaderStyleV2,
};
