import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { notification } from 'antd';
import API from '../services';

const initialState = {
  activeStudent: {},
  studentCourses: [],
  coursesPoints: [],
  calendlyInvitees: [],
  teachers: [],
  calendlyStats: {},
  customer: {},
  tasks: [],
  userTasks: [],
  moderation: [],
  noPackagesCustomers: [],
  activeStudentStats: {},
  activeStudentPoints: {},
  activeStudentRewards: [],
  activeStudentActivities: [],
  activeStudentSubscriptionHistory: [],
  activeStudentOrders: [],
  activeStudentShopOrders: [],
  activeStudentTickets: [],
  activeStudentTasks: [],
  activeStudentDetails: {},
  activeStudentConversations: [],
  activeStudentCalendlyInvitees: [],
  activeStudentActivityGrid: [],
  activeStudentToken: {},
  activeStudentActiveFlags: [],
  activeStudentAllFlags: [],
  activeStudentCart: [],
  productsInCourse: [],
  isLoading: false,
  otherLoadings: {
    activeStudentsStats: false,
    activeStudentsPoints: false,
    activeStudentsRewards: false,
    calendlyStats: false,
  },
};

export const getStudentById = createAsyncThunk('students/getStudentById', async userId => {
  if (userId === '') {
    return { data: {} };
  }
  return API.UserService.getUserById(userId);
});

export const getStudentByEmail = createAsyncThunk('students/getStudentByEmail', async email => {
  return API.UserService.getUserByEmail(email);
});

export const getStudentCoursesAsAdmin = createAsyncThunk('students/getStudentCoursesAsAdmin', async userId => {
  return API.StudentsService.getStudentCoursesAsAdmin(userId);
});

export const getCoursesAsStudent = createAsyncThunk('students/getCourses', async () => {
  return API.StudentsService.getCourses();
});

export const getStudentSubscriptionHistory = createAsyncThunk(
  'students/getStudentSubscriptionHistory',
  async userId => {
    return API.SubscriptionsService.getStudentSubscriptionHistory(userId);
  },
);

export const getStudentCart = createAsyncThunk('students/getStudentCart', async userId => {
  return API.CrmService.getStudentCart(userId);
});

export const addProductToStudentCart = createAsyncThunk(
  'students/addProductToStudentCart',
  async ({ userId, productId }) => {
    return API.CrmService.addProductToStudentCart({ userId, productId });
  },
);

export const removeProductFromStudentCart = createAsyncThunk(
  'students/removeProductFromStudentCart',
  async ({ userId, productId }) => {
    return API.CrmService.removeProductFromStudentCart({ userId, productId });
  },
);

export const addCouponToStudentCart = createAsyncThunk(
  'students/addCouponToStudentCart',
  async ({ userId, coupon }) => {
    return API.CrmService.addCouponToStudentCart({ userId, coupon });
  },
);

export const addRecommendationCodeToStudentCart = createAsyncThunk(
  'students/addRecommendationCodeToStudentCart',
  async ({ userId, recommendationCode }) => {
    return API.CrmService.addRecommendationCodeToStudentCart({ userId, recommendationCode });
  },
);

export const getProductsInCourse = createAsyncThunk('students/getProductsInCourse', async courseId => {
  return API.CrmService.getProductsInCourse(courseId);
});

export const assignPointsToStudentsCourse = createAsyncThunk('students/assignPoints', async (payload, thunkApi) => {
  const gettingPoints = await API.RewardsService.getStudentsPointsAsAdmin(payload);
  thunkApi.dispatch(
    // eslint-disable-next-line no-use-before-define
    assignPointsToStudentsCourseAsAdmin({
      points: gettingPoints.data,
      courseId: payload.courseId,
    }),
  );
});

export const getActiveStudentStats = createAsyncThunk(
  'students/getActiveStudentStats',
  async ({ userId, courseId }) => {
    return API.StudentsService.getStudentStats(userId, courseId);
  },
);

export const getActiveStudentPoints = createAsyncThunk(
  'students/getActiveStudentPoints',
  async ({ userId, courseId }) => {
    return API.RewardsService.getStudentsPointsAsAdmin({ userId, courseId });
  },
);

export const getCalendlyInvitees = createAsyncThunk(
  'crm/getCalendlyInvitees',
  async ({ page, limit, search, filter, filterCancelled, startDate, endDate }) => {
    return API.CrmService.getCalendlyInvitees({ page, limit, search, filter, filterCancelled, startDate, endDate });
  },
);

export const getCustomer = createAsyncThunk('crm/getCustomer', async email => {
  return API.CrmService.getCustomer(email);
});

export const getActiveStudentActivities = createAsyncThunk('crm/getActiveStudentActivities', async email => {
  return API.CrmService.getActiveStudentActivities(email);
});

export const getActiveStudentRewards = createAsyncThunk(
  'students/getActiveStudentRewards',
  async ({ userId, courseId }) => {
    return API.RewardsService.getStudentsRewardsAsAdmin({ userId, courseId });
  },
);

export const getActiveUserOrders = createAsyncThunk('crm/getActiveUserOrders', async userId => {
  return API.CrmService.getActiveUserOrders(userId);
});

export const getActiveStudentShopOrders = createAsyncThunk('crm/getActiveStudentShopOrders', async email => {
  return API.CrmService.getActiveUserShopOrders(email);
});

export const getActiveStudentTickets = createAsyncThunk('crm/getActiveStudentTickets', async userID => {
  return API.CrmService.getActiveStudentTickets(userID);
});

export const getModerationList = createAsyncThunk('crm/getModerationList', async () => {
  return API.CrmService.getModerationList();
});

export const getTeachersList = createAsyncThunk('crm/getTeachersList', async () => {
  return API.CrmService.getTeachersList();
});

export const addTask = createAsyncThunk('crm/addTask', async task => {
  return API.CrmService.addTask(task);
});

export const editTask = createAsyncThunk('crm/editTask', async task => {
  return API.CrmService.editTask(task);
});

export const getActiveStudentTasks = createAsyncThunk('crm/getActiveStudentTasks', async ({ email, filter }) => {
  return API.CrmService.getActiveStudentTasks(email, filter);
});

export const getTasks = createAsyncThunk('crm/getTasks', async ({ page, limit, search, filter }) => {
  return API.CrmService.getTasks({ page, limit, search, filter });
});

export const getUserTasks = createAsyncThunk('crm/getUserTasks', async ({ page, limit, search, filter }) => {
  return API.CrmService.getUserTasks({ page, limit, search, filter });
});

export const addConversation = createAsyncThunk('crm/addConversation', async conversation => {
  return API.CrmService.addConversation(conversation);
});

export const editConversation = createAsyncThunk('crm/editConversation', async ({ conversation }) => {
  return API.CrmService.editConversation({ conversation });
});

export const addCustomerDetails = createAsyncThunk('crm/addCustomerDetails', async customer => {
  return API.CrmService.addCustomerDetails(customer);
});

export const getCustomerDetails = createAsyncThunk('crm/getCustomerDetails', async email => {
  return API.CrmService.getCustomerDetails(email);
});

export const getActiveStudentConversations = createAsyncThunk('crm/getActiveStudentConversations', async email => {
  return API.CrmService.getActiveStudentConversations(email);
});

export const getActiveStudentCalendlyInvitees = createAsyncThunk(
  'crm/getActiveStudentCalendlyInvitees',
  async email => {
    return API.CrmService.getActiveStudentCalendlyInvitees(email);
  },
);

export const generateRecommendationCode = createAsyncThunk('crm/generateRecommendationCode', async email => {
  return API.CrmService.generateRecommendationCode(email);
});

export const getActiveStudentTokens = createAsyncThunk('crm/getActiveStudentTokens', async userId => {
  return API.CrmService.getActiveStudentTokens(userId);
});

export const blockUser = createAsyncThunk('crm/blockUser', async userId => {
  return API.CrmService.blockUser(userId);
});

export const unblockUser = createAsyncThunk('crm/unblockUser', async userId => {
  return API.CrmService.unblockUser(userId);
});

export const getActiveStudentActivityGrid = createAsyncThunk('crm/getActiveStudentActivityGrid', async userId => {
  return API.CrmService.getActiveStudentActivityGrid(userId);
});

export const getNoPackagesCustomers = createAsyncThunk(
  'crm/getNoPackagesCustomers',
  async ({ page, limit, search, filter }) => {
    return API.CrmService.getNoPackagesCustomers({ page, limit, search, filter });
  },
);

export const getCalendlyStats = createAsyncThunk('crm/getCalendlyStats', async ({ startDate, endDate }) => {
  return API.CrmService.getCalendlyStats({ startDate, endDate });
});

export const getStudentFlags = createAsyncThunk('crm/getStudentFlags', async userId => {
  return API.CrmService.getStudentFlags(userId);
});

export const getStudentActiveFlags = createAsyncThunk('crm/getStudentActiveFlags', async userId => {
  return API.CrmService.getStudentActiveFlags(userId);
});

export const addFlag = createAsyncThunk('crm/addFlag', async ({ userId, flag }) => {
  return API.CrmService.addFlag({ userId, flag });
});

export const removeFlag = createAsyncThunk('crm/removeFlag', async ({ userId, flagId }) => {
  return API.CrmService.removeFlag({ userId, flagId });
});

export const activateFlag = createAsyncThunk('crm/activateFlag', async ({ userId, flagId }) => {
  return API.CrmService.activateFlag({ userId, flagId });
});

const crmSlice = createSlice({
  name: 'crm',
  initialState,
  reducers: {},
  extraReducers: {
    [getStudentById.pending.toString()]: state => {
      state.isLoading = true;
      state.activeStudent = {};
    },
    [getStudentById.rejected.toString()]: state => {},
    [getStudentById.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.activeStudent = action.payload.data;
    },
    [getStudentCoursesAsAdmin.fulfilled.toString()]: (state, action) => {
      state.studentCourses = action.payload.data;
      state.isLoading = false;
    },
    [getCoursesAsStudent.fulfilled.toString()]: (state, action) => {
      state.studentCourses = action.payload.data;
      state.isLoading = false;
    },
    [getCoursesAsStudent.pending.toString()]: state => {
      state.isLoading = true;
      state.studentCourses = [];
    },
    [getStudentCoursesAsAdmin.pending.toString()]: state => {
      state.studentCourses = [];
    },
    [assignPointsToStudentsCourse.pending.toString()]: state => {
      state.coursesPoints = [];
    },
    [getActiveStudentStats.fulfilled.toString()]: (state, action) => {
      state.activeStudentStats = action.payload.data;
      state.otherLoadings.activeStudentsStats = false;
    },
    [getActiveStudentStats.pending.toString()]: state => {
      state.otherLoadings.activeStudentsStats = true;
      state.activeStudentStats = {};
    },
    [getActiveStudentPoints.fulfilled.toString()]: (state, action) => {
      state.activeStudentPoints = action.payload.data;
      state.otherLoadings.activeStudentsPoints = false;
    },
    [getActiveStudentPoints.pending.toString()]: state => {
      state.activeStudentPoints = {};
      state.otherLoadings.activeStudentsPoints = true;
    },
    [getActiveStudentRewards.fulfilled.toString()]: (state, action) => {
      state.activeStudentRewards = action.payload.data;
      state.otherLoadings.activeStudentsRewards = false;
    },
    [getCalendlyInvitees.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getCalendlyInvitees.rejected.toString()]: state => {
      state.isLoading = false;
    },
    [getCalendlyInvitees.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.calendlyInvitees = action.payload.data;
    },
    [getActiveStudentRewards.pending.toString()]: state => {
      state.activeStudentRewards = [];
      state.otherLoadings.activeStudentsRewards = true;
    },
    [getCustomer.pending.toString()]: state => {
      state.customer = {};
    },
    [getCustomer.fulfilled.toString()]: (state, action) => {
      state.customer = action.payload.data;
    },
    [getActiveStudentActivities.pending.toString()]: state => {
      state.activeStudentActivities = {};
    },
    [getActiveStudentActivities.fulfilled.toString()]: (state, action) => {
      state.activeStudentActivities = action.payload.data;
    },
    [getStudentByEmail.pending.toString()]: state => {
      state.isLoading = true;
      state.activeStudent = {};
    },
    [getStudentByEmail.rejected.toString()]: state => {
      state.isLoading = false;
    },
    [getStudentByEmail.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.activeStudent = action.payload.data;
    },
    [getStudentSubscriptionHistory.pending.toString()]: state => {
      state.activeStudentSubscriptionHistory = [];
    },
    [getStudentSubscriptionHistory.fulfilled.toString()]: (state, action) => {
      state.activeStudentSubscriptionHistory = action.payload.data;
    },
    [getActiveUserOrders.pending.toString()]: state => {
      state.activeStudentOrders = [];
    },
    [getActiveUserOrders.fulfilled.toString()]: (state, action) => {
      state.activeStudentOrders = action.payload.data;
    },
    [getActiveStudentShopOrders.pending.toString()]: state => {
      state.activeStudentShopOrders = [];
    },
    [getActiveStudentShopOrders.fulfilled.toString()]: (state, action) => {
      state.activeStudentShopOrders = action.payload.data;
    },
    [getActiveStudentTickets.pending.toString()]: state => {
      state.activeStudentTickets = [];
    },
    [getActiveStudentTickets.fulfilled.toString()]: (state, action) => {
      state.activeStudentTickets = action.payload.data;
    },
    [getModerationList.fulfilled.toString()]: (state, action) => {
      state.moderation = action.payload.data;
    },
    [addTask.fulfilled.toString()]: (state, action) => {
      notification.success({
        message: 'Sukces',
        description: 'Zadanie zostało dodane',
      });
    },
    [addTask.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się dodać zadania',
        description: `${action.error.message}`,
      });
    },
    [getActiveStudentTasks.pending.toString()]: state => {
      state.activeStudentTasks = [];
    },
    [getActiveStudentTasks.fulfilled.toString()]: (state, action) => {
      state.activeStudentTasks = action.payload.data;
    },
    [editTask.fulfilled.toString()]: (state, action) => {
      notification.success({
        message: 'Sukces',
        description: 'Zadanie zostało zaktualizowane',
      });
    },
    [editTask.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się zaktualizować zadania',
        description: `${action.error.message}`,
      });
    },
    [getTasks.fulfilled.toString()]: (state, action) => {
      state.tasks = action.payload.data;
      state.isLoading = false;
    },
    [getTasks.pending.toString()]: (state, action) => {
      state.tasks = [];
      state.isLoading = true;
    },
    [getTasks.rejected.toString()]: (state, action) => {
      state.tasks = [];
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się pobrać zadań',
        description: `${action.error.message}`,
      });
    },
    [getUserTasks.fulfilled.toString()]: (state, action) => {
      state.userTasks = action.payload.data;
      state.isLoading = false;
    },
    [getUserTasks.pending.toString()]: (state, action) => {
      state.userTasks = [];
      state.isLoading = true;
    },
    [getUserTasks.rejected.toString()]: (state, action) => {
      state.userTasks = [];
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się pobrać zadań',
        description: `${action.error.message}`,
      });
    },
    [addConversation.fulfilled.toString()]: (state, action) => {
      notification.success({
        message: 'Sukces',
        description: 'Rozmowa została dodana',
      });
    },
    [addConversation.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Błąd',
        description: 'Nie udało się dodać rozmowy',
      });
    },
    [editConversation.fulfilled.toString()]: (state, action) => {
      notification.success({
        message: 'Sukces',
        description: 'Rozmowa została edytowana',
      });
    },
    [editConversation.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Błąd',
        description: 'Nie udało się edytowac rozmowy',
      });
    },
    [addCustomerDetails.fulfilled.toString()]: (state, action) => {
      notification.success({
        message: 'Sukces',
        description: 'Dane klienta zostały zaktualizowane',
      });
    },
    [addCustomerDetails.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się zaktualizować danych klienta',
        description: `${action.error.message}`,
      });
    },
    [getCustomerDetails.pending.toString()]: state => {
      state.activeStudentDetails = {};
    },
    [getCustomerDetails.fulfilled.toString()]: (state, action) => {
      state.activeStudentDetails = action.payload.data;
    },
    [getActiveStudentConversations.pending.toString()]: state => {
      state.activeStudentConversations = [];
    },
    [getActiveStudentConversations.fulfilled.toString()]: (state, action) => {
      state.activeStudentConversations = action.payload.data;
    },
    [getActiveStudentCalendlyInvitees.pending.toString()]: state => {
      state.activeStudentCalendlyInvitees = [];
    },
    [getActiveStudentCalendlyInvitees.fulfilled.toString()]: (state, action) => {
      state.activeStudentCalendlyInvitees = action.payload.data;
    },
    [getActiveStudentCalendlyInvitees.rejected.toString()]: (state, action) => {
      state.activeStudentCalendlyInvitees = [];
      notification.error({
        message: 'Nie udało się pobrać zaproszeń',
        description: `${action.error.message}`,
      });
    },
    [getNoPackagesCustomers.pending.toString()]: state => {
      state.noPackagesCustomers = [];
    },
    [getNoPackagesCustomers.fulfilled.toString()]: (state, action) => {
      state.noPackagesCustomers = action.payload.data;
    },
    [getNoPackagesCustomers.rejected.toString()]: (state, action) => {
      state.noPackagesCustomers = [];
      notification.error({
        message: 'Nie udało się pobrać klientów',
        description: `${action.error.message}`,
      });
    },
    [getActiveStudentTokens.pending.toString()]: state => {
      state.activeStudentToken = [];
    },
    [getActiveStudentTokens.fulfilled.toString()]: (state, action) => {
      state.activeStudentToken = action.payload.data;
    },
    [getCalendlyStats.pending.toString()]: state => {
      state.calendlyStats = {};
      state.otherLoadings.calendlyStats = true;
    },
    [getCalendlyStats.fulfilled.toString()]: (state, action) => {
      state.calendlyStats = action.payload.data;
      state.otherLoadings.calendlyStats = false;
    },
    [getCalendlyStats.rejected.toString()]: (state, action) => {
      state.calendlyStats = {};
      state.otherLoadings.calendlyStats = false;
      notification.error({
        message: 'Nie udało się pobrać statystyk',
        description: `${action.error.message}`,
      });
    },
    [blockUser.fulfilled.toString()]: (state, action) => {
      notification.success({
        message: 'Sukces',
        description: 'Użytkownik został zablokowany',
      });
    },
    [blockUser.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się zablokować użytkownika',
        description: `${action.error.message}`,
      });
    },
    [unblockUser.fulfilled.toString()]: (state, action) => {
      notification.success({
        message: 'Sukces',
        description: 'Użytkownik został odblokowany',
      });
    },
    [unblockUser.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się odblokować użytkownika',
        description: `${action.error.message}`,
      });
    },
    [getActiveStudentActivityGrid.pending.toString()]: state => {
      state.activeStudentActivityGrid = [];
    },
    [getActiveStudentActivityGrid.fulfilled.toString()]: (state, action) => {
      state.activeStudentActivityGrid = action.payload.data;
    },
    [generateRecommendationCode.fulfilled.toString()]: (state, action) => {
      notification.success({
        message: 'Sukces',
        description: 'Kod został wygenerowany',
      });
    },
    [generateRecommendationCode.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się wygenerować kodu',
        description: `${action.error.message}`,
      });
    },
    [getStudentFlags.fulfilled.toString()]: (state, action) => {
      state.activeStudentAllFlags = action.payload.data;
    },
    [getStudentActiveFlags.fulfilled.toString()]: (state, action) => {
      state.activeStudentActiveFlags = action.payload.data;
    },
    [addFlag.fulfilled.toString()]: (state, action) => {
      notification.success({
        message: 'Sukces',
        description: 'Flaga została dodana',
      });
    },
    [addFlag.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się dodać flagi',
        description: `${action.error.message}`,
      });
    },
    [removeFlag.fulfilled.toString()]: (state, action) => {
      notification.success({
        message: 'Sukces',
        description: 'Flaga została usunięta',
      });
    },
    [removeFlag.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się usunąć flagi',
        description: `${action.error.message}`,
      });
    },
    [activateFlag.fulfilled.toString()]: (state, action) => {
      notification.success({
        message: 'Sukces',
        description: 'Flaga została aktywowana',
      });
    },
    [activateFlag.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się aktywować flagi',
        description: `${action.error.message}`,
      });
    },
    [getTeachersList.fulfilled.toString()]: (state, action) => {
      state.teachers = action.payload.data;
    },
    [getTeachersList.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się pobrać listy nauczycieli',
        description: `${action.error.message}`,
      });
    },
    [getStudentCart.pending.toString()]: state => {
      state.activeStudentCart = {};
    },
    [getStudentCart.fulfilled.toString()]: (state, action) => {
      state.activeStudentCart = action.payload.data;
    },
    [getStudentCart.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się pobrać koszyka',
        description: `${action.error.message}`,
      });
    },
    [addProductToStudentCart.fulfilled.toString()]: (state, action) => {
      notification.success({
        message: 'Sukces',
        description: 'Produkt został dodany do koszyka',
      });
    },
    [addProductToStudentCart.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się dodać produktu do koszyka',
        description: `${action.error.message}`,
      });
    },
    [removeProductFromStudentCart.fulfilled.toString()]: (state, action) => {
      notification.success({
        message: 'Sukces',
        description: 'Produkt został usunięty z koszyka',
      });
    },
    [removeProductFromStudentCart.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się usunąć produktu z koszyka',
        description: `${action.error.message}`,
      });
    },
    [addCouponToStudentCart.fulfilled.toString()]: (state, action) => {
      notification.success({
        message: 'Sukces',
        description: 'Kod rabatowy został dodany do koszyka',
      });
    },
    [addCouponToStudentCart.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się dodać kodu rabatowego do koszyka',
        description: `${action.error.message}`,
      });
    },
    [addRecommendationCodeToStudentCart.fulfilled.toString()]: (state, action) => {
      notification.success({
        message: 'Sukces',
        description: 'Kod polecający został dodany do koszyka',
      });
    },
    [addRecommendationCodeToStudentCart.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się dodać kodu polecającego do koszyka',
        description: `${action.error.message}`,
      });
    },
    [getProductsInCourse.pending.toString()]: state => {
      state.productsInCourse = [];
    },
    [getProductsInCourse.fulfilled.toString()]: (state, action) => {
      state.productsInCourse = action.payload.data;
    },
    [getProductsInCourse.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się pobrać produktów',
        description: `${action.error.message}`,
      });
    },
  },
});

export default crmSlice.reducer;
