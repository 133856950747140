import React, { lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';

const Courses = lazy(() => import('../container/courses'));
const StudentCoursesMaterials = lazy(() => import('../container/courses/overview/StudentCoursesMaterials'));
const CourseMaterialDetails = lazy(() => import('../container/courses/overview/CourseMaterialDetails'));
const CourseDashboard = lazy(() => import('../container/courses/overview/CourseDashboard'));
const TeacherDashboard = lazy(() => import('../container/courses/overview/TeacherDashboard'));
const Rewards = lazy(() => import('../container/rewards/overview/Rewards'));
const Sections = lazy(() => import('../container/materials/overview/Sections'));
const SingleSubject = lazy(() => import('../container/materials/overview/SingleSubject'));
const Points = lazy(() => import('../container/points/overview/Points'));
const QuestionDatabase = lazy(() => import('../container/questionDatabase/QuestionDatabase'));
const Modules = lazy(() => import('../container/modules/Modules'));
const CoursesModules = lazy(() => import('../container/coursesModules/'));
const CoursesFaq = lazy(() => import('../container/coursesFaq/'));
const FAQ = lazy(() => import('../container/FAQ/FAQ'));
const CourseNegativePoints = lazy(() => import('../container/coursesNegativePoints'));

const CoursesRoutes = () => {
  const { path } = useRouteMatch();
  const { user } = useSelector(state => state.user);

  return (
    <Switch>
      <Route exact path={path} component={Courses} />
      <Route exact path={`${path}/:courseId/edit/modules`} component={CoursesModules} />
      <Route exact path={`${path}/:courseId/edit/faq`} component={CoursesFaq} />
      <Route exact path={`${path}/:courseId/edit/negative-points`} component={CourseNegativePoints} />
      <Route
        exact
        path={`${path}/:courseId/dashboard`}
        component={user.role === 'STUDENT' ? CourseDashboard : TeacherDashboard}
      />
      <Route
        exact
        path={`${path}/:courseId/materials`}
        render={() =>
          (user.isActive &&
            !user.isActiveSubscriptionPayment &&
            user.paymentType === 'PACKAGES_V1' &&
            user.paymentType !== 'PACKAGES_V2' &&
            user.paymentType !== 'SUBSCRIPTIONS_V1') ||
          user.role !== 'STUDENT' ? (
            <Route exact path={`${path}/:courseId/materials`} component={StudentCoursesMaterials} />
          ) : (
            <Redirect to="/settings/profile" />
          )
        }
      />
      <Route exact path={`${path}/:courseId/materials/:materialId/sections`} component={Sections} />
      <Route
        path={`${path}/:courseId/materials/:materialId/sections/:sectionId/:subjectType/:subjectId`}
        component={SingleSubject}
      />
      <Route path={`${path}/:courseId/materials/:materialId`} component={CourseMaterialDetails} />
      <Route path={`${path}/:courseId/rewards`} component={Rewards} />
      <Route path={`${path}/:courseId/points`} component={Points} />
      {process.env.REACT_APP_IS_QUESTION_DATABASE_ENABLED === 'true' && (
        <Route path={`${path}/:courseId/question-database`} component={QuestionDatabase} />
      )}
      {process.env.REACT_APP_IS_NEW_MODULES_ENABLED === 'true' && (
        <Route
          exact
          path={`${path}/:courseId/modules`}
          render={() =>
            user.isActive &&
            (!user.isActivePaymentForPackages ||
              user.paymentType === 'PACKAGES_V2' ||
              user.paymentType === 'SUBSCRIPTIONS_V1') &&
            user.paymentType !== 'PACKAGES_V1' ? (
              <Route path={`${path}/:courseId/modules`} component={Modules} />
            ) : (
              <Redirect to="/settings/profile" />
            )
          }
        />
      )}
      <Route path={`${path}/:courseId/faqs`} component={FAQ} />
    </Switch>
  );
};

export default CoursesRoutes;
