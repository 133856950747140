/* eslint-disable import/no-cycle */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import API from '../services';

const initialState = {
  isLoading: false,
  ratings: [],
  isSendingLectureRateLoading: false,
  isLectureAlreadyRated: false,
  lectureInformations: {},
  modulesStatsData: [],
  modulesAvgRating: 0,
  isModulesStatsLoading: false,
  sectionsStatsData: [],
  sectionsAvgRating: 0,
  isSectionsStatsLoading: false,
  lecturesStatsData: [],
  lecturesAvgRating: 0,
  isLecturesStatsLoading: false,
  singleLectureStatsData: {},
  isSingleLectureStatsLoading: false,
  subjectContentVersions: [],
  isSubjectContentVersionsLoading: false,
  subjectRatings: {
    data: [],
    total: 0,
  },
  isSubjectRatingsLoading: false,
  allSectionsStats: [],
  allSectionsAvgRating: 0,
  isAllSectionsStatsLoading: false,
  allLecturesStats: [],
  allLecturesAvgRating: 0,
  isAllLecturesStatsLoading: false,
};

export const getAllRatings = createAsyncThunk(
  'ratings/getAllRatings',
  async ({ page, limit, search, isWithComment, selectRating }) => {
    return API.RatingsService.getAllRatings(page, limit, search, isWithComment, selectRating);
  },
);

export const postLectureRate = createAsyncThunk('ratings/postLectureRate', async payload => {
  return API.RatingsService.postLectureRate(payload);
});

export const getLectureInformations = createAsyncThunk('ratings/getLectureInformations', async subjectId => {
  return API.RatingsService.getLectureInformations(subjectId);
});

export const getAllSectionsStats = createAsyncThunk(
  'ratings/getAllSectionsStats',
  async ({ courseId, fromDate, toDate }) => {
    return API.RatingsService.getAllSectionsStats({
      courseId,
      fromDate,
      toDate,
    });
  },
);

export const getAllLecturesStats = createAsyncThunk(
  'ratings/getAllLecturesStats',
  async ({ fromDate, toDate, courseId }) => {
    return API.RatingsService.getAllLecturesStats({
      fromDate,
      toDate,
      courseId,
    });
  },
);

export const getModulesStats = createAsyncThunk('ratings/getModulesStats', async ({ courseId, fromDate, toDate }) => {
  return API.RatingsService.getModulesStats({
    courseId,
    fromDate,
    toDate,
  });
});

export const getSectionsStats = createAsyncThunk('ratings/getSectionsStats', async ({ moduleId, fromDate, toDate }) => {
  return API.RatingsService.getSectionsStats({
    moduleId,
    fromDate,
    toDate,
  });
});

export const getLecturesStats = createAsyncThunk(
  'ratings/getLecturesStats',
  async ({ sectionId, fromDate, toDate }) => {
    return API.RatingsService.getLecturesStats({
      sectionId,
      fromDate,
      toDate,
    });
  },
);

export const getSingleLectureStats = createAsyncThunk(
  'ratings/getSingleLectureStats',
  async ({ subjectId, fromDate, toDate, contentVersion }) => {
    return API.RatingsService.getSingleLectureStats({
      subjectId,
      fromDate,
      toDate,
      contentVersion,
    });
  },
);

export const getSubjectContentVersions = createAsyncThunk('ratings/getSubjectContentVersions', async subjectId => {
  return API.RatingsService.getSubjectContentVersions(subjectId);
});

export const getSubjectRatings = createAsyncThunk(
  'ratings/getSubjectRatings',
  async ({ from, to, sortingType, page, limit, contentVersion, subjectId }) => {
    return API.RatingsService.getSubjectRatings({
      from,
      to,
      sortingType,
      page,
      limit,
      contentVersion,
      subjectId,
    });
  },
);

const ratingsSlice = createSlice({
  name: 'ratings',
  initialState,
  reducers: {},
  extraReducers: {
    [getAllRatings.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getAllRatings.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się pobrać ostrzeżeń',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getAllRatings.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.ratings = action.payload.data;
    },
    [postLectureRate.pending.toString()]: state => {
      state.isSendingLectureRateLoading = true;
    },
    [postLectureRate.fulfilled.toString()]: state => {
      state.isSendingLectureRateLoading = false;
    },
    [postLectureRate.rejected.toString()]: (state, action) => {
      state.isSendingLectureRateLoading = false;
      notification.error({
        message: 'Nie udało się przesłać opinii!',
        description: `${
          action.error.message === 'Rating already exists'
            ? 'Opinia została już wystawiona'
            : action.error.message === 'Rating must be between 1 and 5'
            ? 'Opinia musi być w skali od 1 do 5'
            : action.error.message === 'Assigned lecture not found'
            ? 'Oceniany wykład nie został znaleziony'
            : action.error.message === 'Lecture not found'
            ? 'Wykład nie został znaleziony'
            : ''
        }`,
        placement: 'bottomRight',
      });
    },
    [getLectureInformations.pending.toString()]: state => {},
    [getLectureInformations.rejected.toString()]: (state, action) => {
      notification.error({
        message: 'Nie udało się pobrać informacji o wykładzie',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getLectureInformations.fulfilled.toString()]: (state, action) => {
      state.lectureInformations = action.payload.data;
    },
    [getModulesStats.pending.toString()]: state => {
      state.isModulesStatsLoading = true;
    },
    [getModulesStats.rejected.toString()]: (state, action) => {
      state.isModulesStatsLoading = false;
      notification.error({
        message: 'Nie udało się pobrać statystyk',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getModulesStats.fulfilled.toString()]: (state, action) => {
      state.isModulesStatsLoading = false;
      state.modulesStatsData = action.payload.data.modulesWithAvgRating;
      state.modulesAvgRating = action.payload.data.avgFromAllLectures;
    },
    [getSectionsStats.pending.toString()]: state => {
      state.isSectionsStatsLoading = true;
    },
    [getSectionsStats.rejected.toString()]: (state, action) => {
      state.isSectionsStatsLoading = false;
      notification.error({
        message: 'Nie udało się pobrać statystyk',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getSectionsStats.fulfilled.toString()]: (state, action) => {
      state.isSectionsStatsLoading = false;
      state.sectionsStatsData = action.payload.data.sectionsWithAvgRating;
      state.sectionsAvgRating = action.payload.data.avgFromAllLectures;
    },
    [getLecturesStats.pending.toString()]: state => {
      state.isLecturesStatsLoading = true;
    },
    [getLecturesStats.rejected.toString()]: (state, action) => {
      state.isLecturesStatsLoading = false;
      notification.error({
        message: 'Nie udało się pobrać statystyk',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getLecturesStats.fulfilled.toString()]: (state, action) => {
      state.isLecturesStatsLoading = false;
      state.lecturesStatsData = action.payload.data.lecturesWithAvgRating;
      state.lecturesAvgRating = action.payload.data.avgFromAllLectures;
    },
    [getSingleLectureStats.pending.toString()]: state => {
      state.isSingleLectureStatsLoading = true;
    },
    [getSingleLectureStats.rejected.toString()]: (state, action) => {
      state.isSingleLectureStatsLoading = false;
      notification.error({
        message: 'Nie udało się pobrać statystyk',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getSingleLectureStats.fulfilled.toString()]: (state, action) => {
      state.isSingleLectureStatsLoading = false;
      state.singleLectureStatsData = action.payload.data;
    },
    [getSubjectContentVersions.pending.toString()]: state => {
      state.isSubjectContentVersionsLoading = true;
    },
    [getSubjectContentVersions.rejected.toString()]: (state, action) => {
      state.isSubjectContentVersionsLoading = false;
      notification.error({
        message: 'Nie udało się pobrać wersji treści',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getSubjectContentVersions.fulfilled.toString()]: (state, action) => {
      state.isSubjectContentVersionsLoading = false;
      state.subjectContentVersions = action.payload.data;
    },
    [getSubjectRatings.pending.toString()]: state => {
      state.isSubjectRatingsLoading = true;
    },
    [getSubjectRatings.rejected.toString()]: (state, action) => {
      state.isSubjectRatingsLoading = false;
      notification.error({
        message: 'Nie udało się pobrać statystyk',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getSubjectRatings.fulfilled.toString()]: (state, action) => {
      state.isSubjectRatingsLoading = false;
      state.subjectRatings = action.payload.data;
    },
    [getAllSectionsStats.pending.toString()]: state => {
      state.isAllSectionsStatsLoading = true;
    },
    [getAllSectionsStats.rejected.toString()]: (state, action) => {
      state.isAllSectionsStatsLoading = false;
      notification.error({
        message: 'Nie udało się pobrać statystyk',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getAllSectionsStats.fulfilled.toString()]: (state, action) => {
      state.isAllSectionsStatsLoading = false;
      state.allSectionsStats = action.payload.data.sectionsStats;
      state.allSectionsAvgRating = action.payload.data.avgFromAllLectures;
    },
    [getAllLecturesStats.pending.toString()]: state => {
      state.isAllLecturesStatsLoading = true;
    },
    [getAllLecturesStats.rejected.toString()]: (state, action) => {
      state.isAllLecturesStatsLoading = false;
      notification.error({
        message: 'Nie udało się pobrać statystyk',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getAllLecturesStats.fulfilled.toString()]: (state, action) => {
      state.isAllLecturesStatsLoading = false;
      state.allLecturesStats = action.payload.data.lecturesStats;
      state.allLecturesAvgRating = action.payload.data.avgFromAllLectures;
    },
  },
});

export default ratingsSlice.reducer;
