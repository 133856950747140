import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { notification } from 'antd';
import API from '../services';

const initialState = {
  students: [],
  studentsV2: [],
  activeStudent: {},
  activeStudentsWithoutSubscription: [],
  studentCourses: [],
  coursesPoints: [],
  isLoading: false,
  isLoadingActiveUsers: false,
  activeUsersCount: 0,
  todayUsersLoggedCount: 0,
  activeUsersStats: {},
  isLoadingActiveUsersStats: false,
};

export const getActiveUsers = createAsyncThunk('students/getActiveUsers', async () => {
  return API.StudentsService.getActiveUsers();
});

export const getActiveUsersStats = createAsyncThunk('students/getActiveUsersStats', async () => {
  return API.StudentsService.getActiveUsersStats();
});

export const getAllStudents = createAsyncThunk('students/getAllStudents', async () => {
  return API.StudentsService.getAllStudents();
});

export const getStudents = createAsyncThunk('students/getStudents', async ({ page, limit, search }) => {
  return API.StudentsService.getStudents({ page, limit, search });
});

export const getStudentById = createAsyncThunk('students/getStudentById', async userId => {
  return API.UserService.getUserById(userId);
});

export const getStudentCoursesAsAdmin = createAsyncThunk('students/getStudentCoursesAsAdmin', async userId => {
  return API.StudentsService.getStudentCoursesAsAdmin(userId);
});

export const getCoursesAsStudent = createAsyncThunk('students/getCourses', async () => {
  return API.StudentsService.getCourses();
});

export const getActiveStudentsWithoutSubscription = createAsyncThunk(
  'students/getActiveStudentsWithoutSubscription',
  async payload => {
    return API.StudentsService.getActiveStudentsWithoutSubscription(payload);
  },
);

export const assignPointsToStudentsCourse = createAsyncThunk('students/assignPoints', async (payload, thunkApi) => {
  const gettingPoints = await API.RewardsService.getStudentsPointsAsAdmin(payload);
  thunkApi.dispatch(
    // eslint-disable-next-line no-use-before-define
    assignPointsToStudentsCourseAsAdmin({
      points: gettingPoints.data,
      courseId: payload.courseId,
    }),
  );
});

const studentsSlice = createSlice({
  name: 'students',
  initialState,
  reducers: {
    assignPointsToStudentsCourseAsAdmin: (state, action) => {
      const { coursesPoints } = current(state);
      const newCoursesPoints = [
        ...coursesPoints,
        { courseId: action.payload.courseId, points: action.payload.points.assignedPoints },
      ];
      state.coursesPoints = newCoursesPoints;
    },
  },
  extraReducers: {
    [getAllStudents.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getAllStudents.rejected.toString()]: state => {
      state.isLoading = true;
    },
    [getAllStudents.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.students = action.payload.data;
    },
    [getActiveUsers.pending.toString()]: state => {
      state.isLoadingActiveUsers = true;
    },
    [getActiveUsers.rejected.toString()]: state => {
      state.isLoadingActiveUsers = true;
    },
    [getActiveUsers.fulfilled.toString()]: (state, action) => {
      state.isLoadingActiveUsers = false;
      state.activeUsersCount = action.payload.data.activeUsersCount;
      state.todayUsersLoggedCount = action.payload.data.todayUsersLoggedCount;
    },
    [getStudentById.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getStudentById.rejected.toString()]: state => {
      state.isLoading = true;
    },
    [getStudentById.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.activeStudent = action.payload.data;
    },
    [getStudentCoursesAsAdmin.fulfilled.toString()]: (state, action) => {
      state.studentCourses = action.payload.data;
    },
    [getCoursesAsStudent.fulfilled.toString()]: (state, action) => {
      state.studentCourses = action.payload.data;
    },
    [getActiveStudentsWithoutSubscription.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getActiveStudentsWithoutSubscription.rejected.toString()]: state => {
      state.isLoading = true;
    },
    [getActiveStudentsWithoutSubscription.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.activeStudentsWithoutSubscription = action.payload.data;
    },
    [getStudents.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getStudents.rejected.toString()]: state => {
      state.isLoading = true;
      notification.error({
        message: 'Error',
        description: 'Error while fetching students',
      });
    },
    [getStudents.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.studentsV2 = action.payload.data;
    },
    [getActiveUsersStats.pending.toString()]: state => {
      state.isLoadingActiveUsersStats = true;
    },
    [getActiveUsersStats.rejected.toString()]: state => {
      state.isLoadingActiveUsersStats = true;
    },
    [getActiveUsersStats.fulfilled.toString()]: (state, action) => {
      state.isLoadingActiveUsersStats = false;
      state.activeUsersStats = action.payload.data;
    },
  },
});

export const { assignPointsToStudentsCourseAsAdmin } = studentsSlice.actions;

export default studentsSlice.reducer;
