/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import API from '../services';

const initialState = {
  criticalAnnouncement: {
    isActive: false,
    text: '',
  },
  adminCriticalAnnouncement: {
    isActive: false,
    text: '',
  },
  isLoading: false,
};

export const getCriticalAnnouncement = createAsyncThunk('criticalAnnouncement/getCriticalAnnouncement', async () => {
  return API.CriticalAnnoucementsService.getCriticalAnnouncement();
});

export const getCriticalAnnouncementForAdmin = createAsyncThunk(
  'criticalAnnouncement/getCriticalAnnouncementForAdmin',
  async () => {
    return API.CriticalAnnoucementsService.getCriticalAnnouncementForAdmin();
  },
);

export const setCriticalAnnouncement = createAsyncThunk(
  'criticalAnnouncement/setCriticalAnnouncement',
  async payload => {
    return API.CriticalAnnoucementsService.setCriticalAnnouncement(payload);
  },
);

const criticalAnnoucementsSlice = createSlice({
  name: 'criticalannoucements',
  initialState,
  extraReducers: {
    [getCriticalAnnouncement.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getCriticalAnnouncement.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się wczytać ogłoszenia!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getCriticalAnnouncement.fulfilled.toString()]: (state, action) => {
      state.criticalAnnouncement = action.payload.data;
      state.isLoading = false;
    },
    [setCriticalAnnouncement.pending.toString()]: state => {
      state.isLoading = true;
    },
    [setCriticalAnnouncement.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się ustawić ogłoszenia!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [setCriticalAnnouncement.fulfilled.toString()]: state => {
      state.isLoading = false;
      notification.success({
        message: 'Ustawiono ogłoszenie!',
        placement: 'bottomRight',
      });
    },
    [getCriticalAnnouncementForAdmin.pending.toString()]: state => {
      state.isLoading = true;
    },
    [getCriticalAnnouncementForAdmin.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      notification.error({
        message: 'Nie udało się wczytać ogłoszenia!',
        description: `${action.error.message}`,
        placement: 'bottomRight',
      });
    },
    [getCriticalAnnouncementForAdmin.fulfilled.toString()]: (state, action) => {
      state.adminCriticalAnnouncement = action.payload.data;
      state.isLoading = false;
    },
  },
});

export default criticalAnnoucementsSlice.reducer;
