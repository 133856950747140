import { APIService, APIServiceHealthCheck } from './ApiService';

export const getActiveUsers = () => APIServiceHealthCheck.get('users/active-users');

export const getActiveUsersStats = () => APIServiceHealthCheck.get('users/platform-activity');

export const getAllStudents = () => APIService.get('users/students');

export const getStudents = ({ page, limit, search }) =>
  APIService.get(`v2/users/students?page=${page}&limit=${limit}&search=${search}`);

export const getStudentCoursesAsAdmin = userId => APIService.get(`courses/users/${userId}`);

export const getCourses = () => APIService.get(`courses`);

export const unlockFreeMaterial = payload => APIService.post('materials/users/sections', { ...payload });

export const getStudentStats = (userId, courseId) =>
  APIService.get(`materials/courses/${courseId}/user/${userId}/stats`);

export const getActiveStudentsWithoutSubscription = payload =>
  APIService.get('users/activestudentswithnosubscription', { params: payload });
