import React from 'react';
import { Layout } from 'antd';
import './BlackFridayStyles.css';

const CriticalAlertHeader = ({ text }) => {
  const { Header } = Layout;
  const className =
    process.env.REACT_APP_THEME === 'KMM' ? 'page-no-subscription-kmm-header' : 'page-no-subscription-header';

  return (
    <Header
      className={className}
      id="page-no-subscription-header"
      style={{
        position: 'fixed',
        width: '100%',
        top: '50px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px',
        margin: '0px',
      }}
    >
      <div id="no-subscription-header-text">{text}</div>
    </Header>
  );
};

export default CriticalAlertHeader;
